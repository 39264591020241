<template>
  <div>
    <router-link :to="{ name: 'AddNews' }">
      <button class="btn btn-success mt-4" type="button">Создать</button>
    </router-link>
    <table class="table table-striped">
      <caption>News list table</caption>
      <thead>
      <tr>
        <th scope="col">Id</th>
        <th scope="col">Заголовок</th>
        <th scope="col">Категория</th>
        <th scope="col">Просмотры</th>
        <th scope="col">Дата создания</th>
        <th scope="col">Дата обновления</th>
        <th scope="col">Редактировать</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="news in allNews" v-bind:key="news.id">
        <td>{{ news.id }}</td>
        <td>{{ news.title }}</td>
        <td>{{ news.category ? news.category.title : "-" }}</td>
        <td>{{ news.views }}</td>
        <td>{{ news.createdDate }}</td>
        <td>{{ news.modifiedDate }}</td>
        <td>
          <router-link :to="{ name: 'AddNews', params: { post: news } }">
            <button class="btn-link"> ✏️</button>
          </router-link>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'News',

  data: function () {
    return {
      saveButtonDisabled: false
    }
  },

  computed: {
    ...mapGetters(['allNews']),
  },

  methods: {
    ...mapActions(["fetchNewsList"]),
    save: async function () {

      let pass = atob(prompt("Пароль"));
      let data = pass.split(':');

      let date = new Date(data[1])
      let today = new Date()

      if (data[0] === 'moment'
          && date.getFullYear() == today.getFullYear()
          && date.getMonth() == today.getMonth()
          && date.getDate() == today.getDate()
      ) {
        this.saveButtonDisabled = true
        await this.updatePairs()
        this.saveButtonDisabled = false
      } else {
        alert("Неверный пароль")
      }
    },

    fraction: function (percent) {
      if (percent === 0) return 0
      if (percent == "-") return;
      return (percent / 100);
    }
  },

  filters: {
    percent: function (fraction) {
      return (fraction * 100).toFixed(2);
    }
  },

  mounted() {
    this.fetchNewsList({page: 1, size: 100})
  }
}
</script>